<template>
  <div>
    <div class="row justify-content-center align-items-center py-4 form-rounded-inputs h-100-vh">
      <div class="col-12 col-md-6 col-lg-5">
        <div class="text-center ">
          <img src="@/assets/images/logo-ecart.svg" class=" img-100 " alt="">
          </div>
          <div class="  mt-4 p-4">
            <h4 class="text-center " v-text=" $t('recoveryPassword.title') "></h4>
            <h6 class="text-center my-4 " v-text=" $t('recoveryPassword.message') "></h6>

            <b-alert variant="danger" dismissible fade :show="showAlert">
              <i class=" fa fa-warning "></i> <span v-text="$t('recoveryPassword.alertSeller')"> </span>
            </b-alert>
            <v-observer tag="form" ref="formEmailRecovery" @submit.prevent="fnApiSendEmail()">
              <div class="row">
                <div class="col-12">
                  <v-validation rules="required|email" v-slot="{ errors }" :name=" $t('general.form.email') ">
                    <div class="form-group">
                      <label for="" v-text=" $t('general.form.email') "></label>
                      <input type="email" min="1" placeholder="example@example.com" class="form-control"
                        v-model=" email " :class=" { 'is-invalid':errors.length > 0 } " @focus=" showAlert = false ">
                      <div class="invalid-feedback" v-if="errors.length > 0">
                        {{ errors[0] }}
                      </div>
                    </div>
                  </v-validation>
                </div>
                <div class="col-auto ml-auto">
                  <button class=" btn btn-primary " :disabled="sendingData">
                    <span class="text-capitalize" v-text=" $t('general.button.send') " v-if=" !sendingData "></span>
                    <i class="fa fa-spinner fa-spin " v-else></i>
                  </button>
                </div>
              </div>
            </v-observer>
          </div>

      </div>
    </div>
    
    <div class="page-wrapper form-rounded-inputs d-none">
      <div class="auth-bg">
        <div class="authentication-box">
          <div class="text-center "><img src="@/assets/images/logo-ecart.svg" class=" img-100 " alt=""></div>
          <div class="card mt-4 p-4">
            <h4 class="text-center text-uppercase " v-text=" $t('recoveryPassword.title') "></h4>
            <h6 class="text-center my-4 " v-text=" $t('recoveryPassword.message') "></h6>

            <b-alert variant="danger" dismissible fade :show="showAlert">
              <i class=" fa fa-warning "></i> <span v-text="$t('recoveryPassword.alertSeller')"> </span>
            </b-alert>
            <v-observer tag="form" ref="formEmailRecovery" @submit.prevent="fnApiSendEmail()">
              <div class="row">
                <div class="col-12">
                  <v-validation rules="required|email" v-slot="{ errors }" :name=" $t('general.form.email') ">
                    <div class="form-group">
                      <label for="" v-text=" $t('general.form.email') "></label>
                      <input type="email" min="1" placeholder="example@example.com" class="form-control"
                        v-model=" email " :class=" { 'is-invalid':errors.length > 0 } " @focus=" showAlert = false ">
                      <div class="invalid-feedback" v-if="errors.length > 0">
                        {{ errors[0] }}
                      </div>
                    </div>
                  </v-validation>
                </div>
                <div class="col-auto ml-auto">
                  <button class=" btn btn-primary " :disabled="sendingData">
                    <span class="text-capitalize" v-text=" $t('general.button.send') " v-if=" !sendingData "></span>
                    <i class="fa fa-spinner fa-spin " v-else></i>
                  </button>
                </div>
              </div>
            </v-observer>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return ({

        email: null,
        sendingData: false,
        showAlert: false,
      })
    },
    methods: {

      async fnApiSendEmail() {

        if (await this.$refs['formEmailRecovery'].validate()) {
          this.sendingData = true;
          await axios.post('me/identify', {
            email: this.email
          }).then((response) => {
            this.$toasted.global.infoMessage(this.$t('toasted.info.recoveryPassword'));
            this.$router.push('/login')
          }).catch((error) => {
            this.showAlert = true;
          });
          this.sendingData = false;

        }


      }
    },
    mounted() {}
  }
</script>

<style>

</style>